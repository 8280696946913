import React from "react";

import { ErrorBanner } from "@obby/components";
import { HeaderPlaceholder } from "~/components/HeaderPlaceholder";

import { styles } from "./NotFoundPage.styles";
export function NotFoundPage() {
  return (
    <div className={styles.page()}>
      <HeaderPlaceholder />
      <div className="container-fluid">
        <div className={styles.wrapper()}>
          <ErrorBanner
            title="Something's not right..."
            description="This page no longer exists. Please visit the homepage to continue your journey."
            homeUrl="/"
            imageUrl="https://res.cloudinary.com/obby/image/upload/v1641384467/Misc%20Stickers/obby-little-man.svg"
          />
        </div>
      </div>
    </div>
  );
}

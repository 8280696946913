import { css } from "@obby/components";

export const styles = {
  placeholder: css({
    height: "73px",
    "@lg": {
      height: "90px"
    }
  })
};

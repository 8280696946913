import { css } from "@obby/components";

export const styles = {
  page: css({ height: "100%" }),
  wrapper: css({
    maxWidth: "700px",
    marginX: "auto",
    paddingY: "$5"
  })
};
